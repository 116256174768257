var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.picker.opened),expression:"picker.opened"}],staticClass:"sidebar__picker-background",attrs:{"id":"sidebar_picker-background"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closePicker($event)}}},[_c('div',{staticClass:"sidebar__picker",attrs:{"id":"sidebar_picker"}},[_c('div',{staticClass:"picker__wrap"},[_c('picker',{attrs:{"showSkinTones":false,"showCategories":false,"infiniteScroll":true,"autoFocus":true,"title":"Выберите эмодзи","set":"apple","i18n":{
                            search: 'Поиск',
                            notfound: 'Ничего не найдено',
                            categories: {
                            search: 'Результаты поиска',
                            recent: 'Недавно использованные',
                            people: 'Эмоции и люди',
                            nature: 'Животные и природа',
                            foods: 'Еда и напитки',
                            activity: 'Спорт и активности',
                            places: 'Путешествия и транспорт',
                            objects: 'Объекты',
                            symbols: 'Символы',
                            flags: 'Флаги',
                            custom: 'Custom',
                            }
                        }},on:{"select":_vm.selectEmoji}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({content: 'Выбрать стандартную иконку', classes: 'tooltip--dark'}),expression:"{content: 'Выбрать стандартную иконку', classes: 'tooltip--dark'}"}],staticClass:"picker__default-icon",on:{"click":_vm.selectEmoji}},[_c('svg',{attrs:{"width":"10","height":"12","viewBox":"0 0 10 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M0 0.5C0 0.223858 0.223858 0 0.5 0H9.5C9.77614 0 10 0.223858 10 0.5V11.5C10 11.7761 9.77614 12 9.5 12H0.5C0.223858 12 0 11.7761 0 11.5V0.5ZM2 6.75C2 6.33579 2.33579 6 2.75 6H7.25C7.66421 6 8 6.33579 8 6.75C8 7.16421 7.66421 7.5 7.25 7.5H2.75C2.33579 7.5 2 7.16421 2 6.75ZM2.75 3C2.33579 3 2 3.33579 2 3.75C2 4.16421 2.33579 4.5 2.75 4.5H5.25C5.66421 4.5 6 4.16421 6 3.75C6 3.33579 5.66421 3 5.25 3H2.75Z","fill":"#989CAE"}})])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }