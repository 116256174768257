<template>
    <transition name="fade">
       <div class="sidebar__picker-background" id="sidebar_picker-background" @click.self="closePicker" v-show="picker.opened"> 
            <div
                class="sidebar__picker"
                id="sidebar_picker"
            >
                <div class="picker__wrap">
                    <picker
                        :showSkinTones="false"
                        :showCategories="false"
                        :infiniteScroll="true"
                        :autoFocus="true"
                        title="Выберите эмодзи"
                        set="apple"
                        @select="selectEmoji"
                        :i18n="{
                                search: 'Поиск',
                                notfound: 'Ничего не найдено',
                                categories: {
                                search: 'Результаты поиска',
                                recent: 'Недавно использованные',
                                people: 'Эмоции и люди',
                                nature: 'Животные и природа',
                                foods: 'Еда и напитки',
                                activity: 'Спорт и активности',
                                places: 'Путешествия и транспорт',
                                objects: 'Объекты',
                                symbols: 'Символы',
                                flags: 'Флаги',
                                custom: 'Custom',
                                }
                            }"
                    />
                    <div
                        class="picker__default-icon"
                        @click="selectEmoji"
                        v-tooltip="{content: 'Выбрать стандартную иконку', classes: 'tooltip--dark'}"
                    >
                        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.5C0 0.223858 0.223858 0 0.5 0H9.5C9.77614 0 10 0.223858 10 0.5V11.5C10 11.7761 9.77614 12 9.5 12H0.5C0.223858 12 0 11.7761 0 11.5V0.5ZM2 6.75C2 6.33579 2.33579 6 2.75 6H7.25C7.66421 6 8 6.33579 8 6.75C8 7.16421 7.66421 7.5 7.25 7.5H2.75C2.33579 7.5 2 7.16421 2 6.75ZM2.75 3C2.33579 3 2 3.33579 2 3.75C2 4.16421 2.33579 4.5 2.75 4.5H5.25C5.66421 4.5 6 4.16421 6 3.75C6 3.33579 5.66421 3 5.25 3H2.75Z" fill="#989CAE"/>
                        </svg>
                    </div>
                </div>
            </div>
       </div>
    </transition>
</template>

<script>
import {eventBus} from "@/main";
import session from '@/api/session';
import {mapState} from "vuex";
import {Picker} from "emoji-mart-vue"
import data from 'emoji-mart-vue/data/messenger.json';
export default {
    name: "TreePicker",
    components: {
        Picker
    },
    computed: {
        ...mapState('sidebar', [
            'picker'
        ]),
    },
    methods: {
        async selectEmoji(emoji = null) {
            const params = {
                emoji: emoji.id || '',
                content_type: this.picker.content_type,
                id: this.picker.id
            };
            const request = await session.post(`/api/v1/emoji/`, params);
            eventBus.$emit('change-emoji', {
                id: params.id,
                icon: emoji.id
            });
            this.closePicker();
        },
        closePicker() {
            this.$store.dispatch('sidebar/setPicker', {opened: false, id: null, content_type: ''});
        }
    },
    mounted() {
        /*document.addEventListener('scroll',  () => {
            this.closePicker()
        })*/
    }
}
</script>

<style lang="scss">
@import "#sass/v-style";
.sidebar {
   
    &__picker {
        position: fixed;
        &-background {
            left: 0;
            top: 0;
            position: absolute;
            min-height: 100%;
            z-index: 10001;
        }
        .picker {
            &__wrap {
                position: relative;
            }
            &__default-icon {
                cursor: pointer;
                position: absolute;
                right: 25px;
                bottom: 25px;
                svg {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.emoji-mart-preview-data {
    padding-right: 30px;
}

</style>
